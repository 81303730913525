import { DateTime } from "luxon";
/**
 *
 * @returns {boolean} true if login expired, false otherwise
 */
const CheckLoginExpiration = () => {
    if (localStorage.loginTimeStamp === undefined) {
        return true;
    }
    if (localStorage.loginExpire === "true") {
        return true;
    }
    const lastLoginTimeStamp = DateTime.fromISO(
        JSON.parse(localStorage.loginTimeStamp)
    );
    const currentTimestamp = DateTime.now();
    const diff = currentTimestamp.diff(lastLoginTimeStamp, "hours");
    if (diff.as("hours") > 10) {
        return true;
    } else {
        return false;
    }
};
export default CheckLoginExpiration;
