import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Image, NavDropdown, Spinner, Button, Nav } from "react-bootstrap";
import Axios from "../../axios";
import LanguageSelector from "./LanguageSelector";
const handleLogout = async (setLoading, logout) => {
    setLoading(true);
    try {
        const response = await Axios.post(
            `${process.env.REACT_APP_API_BASE_URL}logout/`
        );
    } catch (err) {
    } finally {
        logout();
        window.location.replace("/");
    }
};
const LoggedIn = () => {
    const { t } = useTranslation(["nav", "common"]);
    const { user, logout, loading, setLoading } = useContext(AuthContext);
    return (
        <>
            <NavDropdown
                id="nav-dropdown"
                title={
                    <Image
                        src={user?.employee_profile_picture}
                        style={{ height: "1.5rem" }}
                        className="me-2"
                        roundedCircle
                    />
                }
                align={{ lg: "end" }}
                menuVariant="dark"
                autoClose="outside"
                className="justify-content-center align-items-center"
            >
                <NavDropdown.Header className="py-0">
                    <span
                        className="text-white fw-bold"
                        style={{ fontSize: "1rem" }}
                    >
                        {user?.employee_profile?.displayName || "User"}
                    </span>
                </NavDropdown.Header>
                {/* <NavDropdown.Item href="#">Personal Center</NavDropdown.Item> */}
                <NavDropdown.Item as="div" className="pb-0">
                    <Button
                        href="/dashboard"
                        target="_blank"
                        variant="outline-primary"
                        className="border border-0 text-light"
                        disabled={loading}
                    >
                        {t("dashboard")}
                    </Button>
                </NavDropdown.Item>
                <NavDropdown.Item as="div" className="pb-0 pt-0">
                    <LanguageSelector />
                </NavDropdown.Item>
                <NavDropdown.Item as="div" className="pt-0">
                    <Button
                        variant="outline-danger"
                        className="border border-0"
                        disabled={loading}
                        onClick={() => {
                            handleLogout(setLoading, logout);
                        }}
                    >
                        {loading && (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                />
                                <span className="ms-2">{t("common:loading")}</span>
                            </>
                        )}
                        {!loading && <span className="fw-bold">{t("common:logout")}</span>}
                    </Button>
                </NavDropdown.Item>
            </NavDropdown>
        </>
    );
};
export default LoggedIn;
