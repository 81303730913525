import { createContext, useState } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [queryResults, setQueryResults] = useState([]);
    const [fetchError, setFetchError] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [data_Table_data, setData_Table_data] = useState([]);
    const [data_Table_columns, setData_Table_columns] = useState([]);
    const [data_Table_col_titles, setData_Table_col_titles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [llmlogger, setLlmlogger] = useState(null);
    const [onePatientInfo, setOnePatientInfo] = useState({});
    const [modalIsEditable, setModalIsEditable] = useState(false);

    return (
        <DataContext.Provider
            value={{
                fetchError,
                setFetchError,
                isLoading,
                setIsLoading,
                queryResults,
                setQueryResults,
                errorShow,
                setErrorShow,
                data_Table_data,
                setData_Table_data,
                data_Table_columns,
                setData_Table_columns,
                data_Table_col_titles,
                setData_Table_col_titles,
                llmlogger,
                setLlmlogger,
                onePatientInfo,
                setOnePatientInfo,
                modalIsEditable,
                setModalIsEditable,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
