import i18next from "i18next";
import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { handleLogin } from "../components/MyNav/LoginButton";
const Login = () => {
    useEffect(() => {
        document.title = i18next.t("pages:login");
    }, []);
    return (
        <Container className="mt-10">
            <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                    <h1>
                        <i className="bi bi-exclamation-triangle-fill text-warning me-3"></i>
                        {i18next.t("pages:please_login_first")}
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center mt-3">
                <Col xs="auto" md={8}>
                    <h3>
                        {i18next.t("pages:redirected_msauth")}{" "}
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={handleLogin}
                            className="link-offset-1-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                        >
                            {i18next.t("common:login")}
                        </a>{" "}
                        {i18next.t("pages:there_with_credentials")}
                    </h3>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
