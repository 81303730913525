import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";

const InitializationFailure = () => {
    const { t } = useTranslation(["errors", "suggestions"]);
    return (
        <Container className="mt-10">
            <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                    <h1>
                        <i className="bi bi-exclamation-triangle-fill text-warning me-3"></i>
                        {t("errors:err_initialization")}
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center mt-3">
                <Col xs="auto">
                    <h3 className="text-center">
                        {t("suggestions:contactAdmin")}
                    </h3>
                </Col>
            </Row>
        </Container>
    );
};

export default InitializationFailure;
