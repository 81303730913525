import { useTranslation } from "react-i18next";
import i18next from "i18next";
import React, { useEffect } from "react";
import { Button, Stack } from "react-bootstrap";
const LanguageSelector = () => {
    const { t } = useTranslation(["common", "components", "components.mynav"]);
    const handleLanguageChange = (option) => {
        i18next.changeLanguage(option);
        window.location.reload();
    };
    useEffect(() => {
        if (
            !localStorage.getItem("i18nextLng") ||
            localStorage.getItem("i18nextLng")?.length > 2
        ) {
            i18next.changeLanguage("en");
        }
    }, []);
    return (
        <Stack direction="horizontal" gap={0}>
            <Button
                variant={
                    localStorage.getItem("i18nextLng") === "fr"
                        ? "outline-light"
                        : "outline-warning"
                }
                className="border border-0"
                disabled={localStorage.getItem("i18nextLng") === "fr"}
                onClick={() => handleLanguageChange("fr")}
            >
                Français
            </Button>
            <Button
                variant={
                    localStorage.getItem("i18nextLng") === "en"
                        ? "outline-light"
                        : "outline-warning"
                }
                className="border border-0"
                disabled={localStorage.getItem("i18nextLng") === "en"}
                onClick={() => handleLanguageChange("en")}
            >
                English
            </Button>
        </Stack>
    );
};

export default LanguageSelector;
