import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Spinner, Stack } from "react-bootstrap";
import Axios from "../../axios";
import { toast } from "react-hot-toast";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import clearLocalStorage from "../../utils/Auth/clearLocalStorage";
export const handleLogin = async (ifToast = true, setLoading = () => {}) => {
    clearLocalStorage();
    if (ifToast) toast.loading(i18next.t("common:loading"));
    setLoading(true);
    try {
        const response = await Axios.get(
            `${process.env.REACT_APP_API_BASE_URL}authorization/`
        );
        window.location.replace(response.data["authorize_url"]);
    } catch (err) {
        toast.error(err.message);
        setLoading(false);
    }
};
const LoginButton = () => {
    const { t } = useTranslation(["nav", "common"]);
    const { loading, setLoading } = useContext(AuthContext);
    return (
        <Button
            variant={loading ? "primary" : "outline-primary"}
            className="border border-0"
            onClick={() => {
                handleLogin(false, setLoading);
            }}
            disabled={loading}
        >
            {loading && (
                <Stack
                    direction="horizontal"
                    gap={1}
                    className="justify-content-sm-center justify-content-lg-between"
                >
                    <Spinner as="span" animation="border" size="sm" />
                    <span className="ms-1">{t("common:loading")}</span>
                </Stack>
            )}
            {!loading && <span className="">{t("common:login")}</span>}
        </Button>
    );
};

export default LoginButton;
