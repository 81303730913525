import axios from "axios";
import i18next from "i18next";
const axiosConfig = (dataUrl, params = {}, method) => {
    const myAxiosRequest = axios.create({
        headers: {
            Authorization: `JWT ${JSON.parse(localStorage.token || '""')}`,
            "Accept-Language": i18next.language,
        },
    });
    myAxiosRequest.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!error.response) {
                error.message = i18next.t("errors:err_unknown");
            } else if (error?.response?.status === 500) {
                error.message = i18next.t("errors:err_500");
            } else if (error?.response?.status === 404) {
                error.message = i18next.t("errors:err_404");
            } else if (error?.response?.status === 403) {
                error.message = i18next.t("errors:err_403");
            } else if (error?.response?.status === 400) {
                error.message = `${i18next.t("errors:err_400")} ${
                    error?.response?.data?.message ||
                    error?.response?.data?.error_description ||
                    Object.values(error?.response?.data).join(" ") ||
                    ""
                } ${error?.response?.data?.city?.message ?? ""} ${
                    error?.response?.data?.email
                        ? error?.response?.data?.email
                        : Object.values(error?.response?.data).join(" ") ?? ""
                } ${
                    error?.response?.data?.error
                        ? error?.response?.data?.error
                        : Object.values(error?.response?.data).join(" ") ?? ""
                }`;
            } else if (error?.response?.status === 405) {
                error.message = `${i18next.t("errors:err_405")} ${
                    error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    Object.values(error?.response?.data).join(" ") ||
                    ""
                }`;
            } else if (error?.response?.status === 401) {
                error.message = `${i18next.t("errors:err_401")} ${
                    error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    Object.values(error?.response?.data).join(" ") ||
                    ""
                }.`;
            } else {
                error.message = i18next.t("errors:err_unknown");
            }
            error.message = `${error.message} ${i18next.t(
                "suggestions:contactAdmin"
            )}`;
            return Promise.reject(error);
        }
    );
    return myAxiosRequest[method](dataUrl, params);
};
export default {
    get: (dataUrl, params) => axiosConfig(dataUrl, params, "get"),
    post: (dataUrl, params) => axiosConfig(dataUrl, params, "post"),
    patch: (dataUrl, params) => axiosConfig(dataUrl, params, "patch"),
    delete: (dataUrl, params) => axiosConfig(dataUrl, params, "delete"),
};
