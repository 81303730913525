import i18next from "i18next";
import Axios from "../../axios";
import ReturnExceptionsError from "../ReturnExceptionsError";
import { toast } from "react-hot-toast";
import handleInitialization from "./handleInitialization";
/**
 * Async function to get all resources from API, and
 * set them to localStorage
 * @returns {boolean}  initialization_success
 */
const AppInitializer = async (
    setLoading,
    setIsAppInitializerSuccess,
    handleCount
) => {
    // here should setLoading(true), but it already handled in Oauth.js:31, so no need to handle it here
    let initialization_success = false;
    try {
        if (JSON.parse(localStorage.initialization || "{}") === "callAPI") {
            const loadingToastId = toast.loading(i18next.t("common:loading"));
            const response_1 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employees/`
            );
            const response_2 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employees/?has_appointments=true`
            );
            const response_3 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}clinics/`
            );
            const response_4 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}rooms/`
            );
            const response_5 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employees-appointment-assignable/?is_appointment_assignable=true`
            );
            const response_6 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employees-appointment-assignable/`
            );
            const response_7 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}provinces/`
            );
            let cities = {};
            for (const province of response_7?.data) {
                const response_of_this_province = await Axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}cities/?province=${province?.choice}`
                );
                cities[province?.choice] = response_of_this_province?.data;
            }
            let response_8 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}choices/patient-status/`
            );
            if (
                response_1.data.length > 0 &&
                response_2.data.length > 0 &&
                response_3.data.length > 0 &&
                response_4.data.length > 0 &&
                response_5.data.length > 0 &&
                response_6.data.length > 0 &&
                response_7.data.length > 0 &&
                response_8.data.length > 0
            ) {
                handleInitialization(
                    response_1.data,
                    response_2.data,
                    response_3.data,
                    response_4.data,
                    response_5.data,
                    response_6.data,
                    response_7.data,
                    cities,
                    response_8.data
                );
                initialization_success = true;
                localStorage.initialization = JSON.stringify("done");
                toast.dismiss(loadingToastId);
                setLoading(false);
                setIsAppInitializerSuccess(true);
                handleCount();
            } else {
                throw new ReturnExceptionsError(
                    i18next.t("errors:employee_resources_error")
                );
            }
        }
        if (JSON.parse(localStorage.initialization || "{}") === "done") {
            initialization_success = true;
        }
    } catch (err) {
        toast.error(err.message);
    } finally {
        console.info(`\u2605 App Initialization Done`);
        return initialization_success;
    }
};
export default AppInitializer;
