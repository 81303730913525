import React, { useEffect, Suspense, useContext } from "react";
import { redirect } from "react-router-dom";
import { DataProvider } from "./context/DataContext";
import ToastComponent from "./components/Toast/ToastComponent";
import MyNav from "./components/MyNav/MyNav";
import InitializationLoadinger from "./components/Initializer/InitializationLoadinger";
import InitializationFailure from "./components/Initializer/InitializationFailure";
import InitializationContext from "./context/InitializationContext";
import AuthContext from "./context/AuthContext";
import CheckLoginExpiration from "./utils/Auth/CheckLoginExpiration";
import LoginExpiration from "./components/Initializer/LoginExpiration";
import AppRoutes from "./routes/AppRoutes";
function App() {
    const {
        initializationLoading,
        setInitializationLoading,
        initializationSuccess,
        setInitializationSuccess,
        ifLoginExpired,
        setIfLoginExpired,
    } = useContext(InitializationContext);
    const { loginExpire, user, logout } = useContext(AuthContext);
    const getResources = () => {
        if (
            localStorage.token === undefined &&
            localStorage.loginExpire === undefined
        ) {
            setInitializationLoading(false);
            setInitializationSuccess(true);
            setIfLoginExpired(false);
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            if (!code) {
                redirect("/login");
            }
        } else if (CheckLoginExpiration()) {
            setInitializationLoading(false);
            setInitializationSuccess(true);
            setIfLoginExpired(true);
            loginExpire();
        } else {
            setInitializationLoading(false);
            setInitializationSuccess(true);
            setIfLoginExpired(false);
        }
    };
    useEffect(() => {
        getResources();
    }, []);
    window.addEventListener(
        "pageshow",
        function (event) {
            if (
                event.persisted ||
                performance.getEntriesByType("navigation")[0].type ===
                    "back_forward"
            ) {
                if (!user?.token) {
                    logout();
                    redirect("/login");
                }
            }
        },
        false
    );
    const error = console.error;
    console.error = (...args) => {
        if (/defaultProps/.test(args[0])) return;
        error(...args);
    };
    return (
        <div className="App">
            <MyNav />
            <ToastComponent />
            {initializationLoading && <InitializationLoadinger />}
            {!initializationLoading && !initializationSuccess && (
                <InitializationFailure />
            )}
            {!initializationLoading && ifLoginExpired && <LoginExpiration />}
            {!initializationLoading &&
                !ifLoginExpired &&
                initializationSuccess && (
                    <DataProvider>
                        <Suspense fallback={<InitializationLoadinger />}>
                            <AppRoutes />
                        </Suspense>
                    </DataProvider>
                )}
        </div>
    );
}

export default App;
