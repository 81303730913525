import { useTranslation } from "react-i18next";
import { Container, Col, Row, Spinner } from "react-bootstrap";

const InitializationLoadinger = () => {
    const { t } = useTranslation(["common"]);
    return (
        <Container className="mt-10">
            <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                    <Spinner animation="border" variant="secondary" />
                </Col>
                <Col xs="auto" className="text-secondary">
                    <h1>{t("common:initializing")}</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default InitializationLoadinger;
