import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Oauth from "../pages/Oauth";
import Login from "../pages/Login";
import RequireAuth from "../utils/Auth/RequireAuth";
const Home = React.lazy(() => import("../pages/Home"));
const QueryFilterPage = React.lazy(() => import("../pages/QueryFilterPage"));
const QueryPromptPage = React.lazy(() => import("../pages/QueryPromptPage"));
const QueryOnePatientPage = React.lazy(() =>
    import("../pages/QueryOnePatientPage")
);
const AppointmentSchedulerPage = React.lazy(() =>
    import("../pages/AppointmentSchedulerPage")
);
const UsersFeedbacksPage = React.lazy(() =>
    import("../pages/UsersFeedbacksPage")
);
const CreateNewPatientPage = React.lazy(() =>
    import("../pages/CreateNewPatientPage")
);
const PhonePreEvaluationPage = React.lazy(() =>
    import("../pages/PhonePreEvaluationPage")
);
const CaregiverInterviewPage = React.lazy(() =>
    import("../pages/CaregiverInterviewPage")
);
const InitialEvaluationPage = React.lazy(() =>
    import("../pages/InitialEvaluationPage")
);
const CreateNewIntakeFormWithPatientUUIDPage = React.lazy(() =>
    import("../pages/CreateNewIntakeFormWithPatientUUIDPage")
);
const DashboardPage = React.lazy(() => import("../pages/DashboardPage"));
const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route
                path="/query-prompt"
                element={
                    <RequireAuth>
                        <QueryPromptPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/patients"
                element={
                    <RequireAuth>
                        <QueryFilterPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/create-new-patient"
                element={
                    <RequireAuth>
                        <CreateNewPatientPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/phone-pre-evaluation/patient/:patientUUID"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="phone-pre-evaluation" />
                    </RequireAuth>
                }
            />
            <Route
                path="/phone-pre-evaluation/:uuid"
                element={
                    <RequireAuth>
                        <PhonePreEvaluationPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/phone-pre-evaluation"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="phone-pre-evaluation" />
                    </RequireAuth>
                }
            />
            <Route
                path="/caregiver-interview/patient/:patientUUID"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="caregiver-interview" />
                    </RequireAuth>
                }
            />
            <Route
                path="/caregiver-interview/:uuid"
                element={
                    <RequireAuth>
                        <CaregiverInterviewPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/caregiver-interview"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="caregiver-interview" />
                    </RequireAuth>
                }
            />
            <Route
                path="/initial-evaluation/patient/:patientUUID"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="initial-evaluation" />
                    </RequireAuth>
                }
            />
            <Route
                path="/initial-evaluation/:uuid"
                element={
                    <RequireAuth>
                        <InitialEvaluationPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/initial-evaluation"
                element={
                    <RequireAuth>
                        <CreateNewIntakeFormWithPatientUUIDPage intakeFormType="initial-evaluation" />
                    </RequireAuth>
                }
            />
            <Route
                path="/patient/:uuid"
                element={
                    <RequireAuth>
                        <QueryOnePatientPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/appointment-scheduler/:date"
                element={
                    <RequireAuth>
                        <AppointmentSchedulerPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/appointment-scheduler"
                element={
                    <RequireAuth>
                        <AppointmentSchedulerPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <RequireAuth>
                        <DashboardPage />
                    </RequireAuth>
                }
            />
            <Route
                path="/users-feedbacks"
                element={
                    <RequireAuth>
                        <UsersFeedbacksPage />
                    </RequireAuth>
                }
            />
            <Route path="/oauth" element={<Oauth />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
