import React from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { CloseButton } from "react-bootstrap";
const ToastComponent = () => {
    return (
        <Toaster
            position="top-right"
            toastOptions={{
                error: {
                    duration: Infinity,
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <>
                            <div className="align-self-start mt-1">{icon}</div>
                            {message}
                            {t.type !== "loading" && (
                                <div className="align-self-start mt-1">
                                    <CloseButton
                                        onClick={() => toast.dismiss(t.id)}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
};

export default ToastComponent;
