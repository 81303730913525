import i18next from "i18next";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
const NotFound = () => {
    useEffect(() => {
        document.title = i18next.t("pages:not_found");
    }, []);
    return (
        <Container className="mt-7">
            <Row className="justify-content-center pt-5">
                <Col xs="auto">
                    <h1>
                        <i className="bi bi-exclamation-triangle-fill text-warning me-3"></i>
                        {i18next.t("errors:err_404")}
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col xs="auto" lg={8}>
                    <h3 className="text-center">
                        {i18next.t("suggestions:check_url")}
                    </h3>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
