import { createContext, useState } from "react";
const InitializationContext = createContext({});

export const InitializationContextProvider = ({ children }) => {
    const [initializationLoading, setInitializationLoading] = useState(true);
    const [initializationSuccess, setInitializationSuccess] = useState(false);
    const [ifLoginExpired, setIfLoginExpired] = useState(false);
    return (
        <InitializationContext.Provider
            value={{
                initializationLoading,
                setInitializationLoading,
                initializationSuccess,
                setInitializationSuccess,
                ifLoginExpired,
                setIfLoginExpired,
            }}
        >
            {children}
        </InitializationContext.Provider>
    );
};

export default InitializationContext;
