import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
i18next
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            backends: [
                HttpBackend,
                resourcesToBackend((lng, ns) =>
                    import(`./locales/${lng}/${ns}.json`)
                ),
            ],
            backendOptions: [
                {
                    loadPath: "/locales/{{lng}}/{{ns}}.json",
                },
            ],
        },
        fallbackLng: "en",
        fallbackNS: "common",
        debug: false,
        ns: [
            "appointment_types",
            "appointments_statuses",
            "auth",
            "caregiver_interview",
            "clinics",
            "common",
            "components.appointmentscheduler",
            "components.dashboard",
            "components",
            "components.mynav",
            "components.onepatient",
            "components.queryfilter",
            "components.queryprompt",
            "components.scanner",
            "errors",
            "home",
            "initial_evaluation_form",
            "intakeForm",
            "misc",
            "nav",
            "pages",
            "phone_pre_evaluation_form_fields",
            "phone_pre_evaluation_questionnaire",
            "scheduler",
            "suggestions",
            "utils.appointmentscheduler",
            "utils.datagrid",
            "utils.intakeforms",
            "utils",
            "utils.onepatient",
        ],
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });
export default i18next;
