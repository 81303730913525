import { createContext, useState } from "react";
import { DateTime } from "luxon";
const AuthContext = createContext({});
import clearLocalStorage from "../utils/Auth/clearLocalStorage";
export const AuthContextDataProvider = ({ children }) => {
    const defaultUser = localStorage.token
        ? {
              token: JSON.parse(localStorage.token),
              employee_profile: JSON.parse(
                  localStorage.employee_profile || '{"displayName":"User"}'
              ),
              employee_profile_picture:
                  localStorage.employee_profile_picture || "",
          }
        : {
              token: null,
              employee_profile: {
                  displayName: "User",
              },
              employee_profile_picture: "",
          };
    const [user, setUser] = useState(defaultUser);
    const [loading, setLoading] = useState(false);
    const login = (newUser) => {
        setUser(newUser);
        localStorage.token = JSON.stringify(newUser.token);
        localStorage.initialization = JSON.stringify("callAPI");
        localStorage.employee_profile = JSON.stringify(
            newUser.employee_profile
        );
        localStorage.employee_profile_picture =
            newUser.employee_profile_picture;
        localStorage.loginTimeStamp = JSON.stringify(DateTime.now());
    };
    const logout = () => {
        setUser(null);
        clearLocalStorage();
        sessionStorage.clear();
    };
    const loginExpire = () => {
        setUser(null);
        clearLocalStorage();
        localStorage.loginExpire = JSON.stringify("true");
    };
    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                login,
                logout,
                loading,
                setLoading,
                loginExpire,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;
