import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/sass/index.scss";
import "./assets/sass/myVariables.scss";
import { InitializationContextProvider } from "./context/InitializationContext";
import { AuthContextDataProvider } from "./context/AuthContext";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <InitializationContextProvider>
            <AuthContextDataProvider>
                <App />
            </AuthContextDataProvider>
        </InitializationContextProvider>
    </BrowserRouter>
    // </React.StrictMode>
);
